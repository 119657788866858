import React from 'react';
import './styles.css';
import { RecommerceExtendedProfile } from '@finn-no/recommerce-extended-profilebox';
import type { RecommerceHydratationData } from '../common/types.js';

const RecommerceApp = (
    props: RecommerceHydratationData,
): React.ReactElement => (
    <RecommerceExtendedProfile {...props.extendedProfileRecommerce} />
);

export default RecommerceApp;
